import React from 'react'
import {BsFillFlagFill} from 'react-icons/bs'
import {AiOutlineCheck} from 'react-icons/ai'
import {FcServices} from 'react-icons/fc'
import {MdOutlineSecurity} from 'react-icons/md'
import {BiCoinStack} from 'react-icons/bi'
import {TbRulerMeasure} from 'react-icons/tb'
import {AiOutlineThunderbolt} from 'react-icons/ai'

const OurVision = () => {
  return (
    <>
    
    <div className='container'>
     <div className='row'>
     <div className='col-lg-11 m-auto'>

      <h1 className='text-center mb-5 text-white'> <span className='  text-rainbow-animation'>Our Vision & solutions </span></h1>
      <div className='row'>
       
      <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
      
      <div class="card cardsvg" style={{backgroundColor:'transparent', color:'#fff', width:'100%', height:'auto'}}>
      <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
        <AiOutlineCheck className='Icon1 p-2' style={{fontSize:'58px',  color:'#fff'}}/>
      </div>
  
        <div class="card-body">
        <h5 class="card-title text-center py-4   text-rainbow-animation">Our Vision: Promoting Safe and Ethical AI Use</h5>
        <p class="card-text pb-5 pt-3" style={{fontWeight:'400', textAlign:'justify', fontSize:'16px'}}> At ArmorAI, we have a clear vision: to promote the safe and ethical use of artificial intelligence (AI). In a world where AI continues to grow and impact various aspects of our lives, it's essential to ensure that it is developed and used responsibly. Our goal is to be a leading provider of AI safety solutions that help ensure AI is used for the benefit of society.</p>

       </div>
</div>

      </div>
       
      <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
      
      <div class="card cardsvg" style={{backgroundColor:'transparent', color:'#fff',height:'auto'}}>
      <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
        <FcServices style={{fontSize:'58px', color:'#fff'}}/>
      </div>
  
        <div class="card-body">
        <h5 class="card-title text-center py-4  text-rainbow-animation ">Introducing ArmorAI: Tools and Services for AI Safety         </h5>
        <p class="card-text pb-3" style={{fontWeight:'400', textAlign:'justify',fontSize:'16px'}}> ArmorAI is our comprehensive suite of tools and services designed to integrate safety measures into AI development and use. We offer software development kits (SDKs) and application programming interfaces (APIs) that developers can use to incorporate our safety measures into their own AI applications. With ArmorAI, developers can enhance the safety, transparency, and explainability of their AI systems.</p>

       </div>
</div>

      </div>
       

      <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
      
      <div class="card cardsvg" style={{backgroundColor:'transparent', color:'#fff',height:'auto'}}>
      <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
        <MdOutlineSecurity className='Icon3 p-2'  style={{fontSize:'58px', color:'#fff'}}/>
      </div>
  
        <div class="card-body">
        <h5 class="card-title text-center py-4   text-rainbow-animation">SafeGPT: Secure Conversations with AI Chatbots  </h5>
        <p class="card-text pb-5" style={{fontWeight:'400', textAlign:'justify',fontSize:'16px'}}>  SafeGPT, one of our flagship offerings, is a chat-based AI tool that enables users to have safe and secure conversations with AI chatbots. We have implemented filters to prevent illegal or harmful uses of AI, ensuring that interactions through SafeGPT are both reliable and secure. Users can engage with AI chatbots with peace of mind, knowing that harmful or inappropriate content is actively filtered out.
        </p>

       </div>
</div>

      </div> 
 
      </div> 


     <div className='row mt-5'>
     <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
      
      <div class="card cardsvg2" style={{backgroundColor:'transparent', color:'#fff',height:'auto'}}>
      <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
        <TbRulerMeasure className='Icon4 p-2' style={{fontSize:'58px', color:'#fff'}}/>
      </div>
  
        <div class="card-body">
        <h5 class="card-title text-center py-4  text-rainbow-animation">Integrating Safety Measures for Responsible AI</h5>
        <p class="card-text mb-5 pb-4" style={{fontWeight:'400', textAlign:'justify',fontSize:'16px'}}>  At ArmorAI, we prioritize the integration of safety measures into AI development and use. Our suite of tools and services encompasses various safeguards, including filters that prevent illegal or harmful uses of AI. Additionally, we provide tools that promote transparency and explainability of AI decisions, helping developers and users understand how AI systems arrive at their conclusions.
        </p>

       </div>
</div>

      </div> 
      
      
     <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
      
      <div class="card cardsvg " style={{backgroundColor:'transparent', color:'#fff', width:'100%',height:'auto'}}>
      <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
        <BiCoinStack className='Icon3 p-2' style={{fontSize:'58px', color:'#fff'}}/>
      </div>
  
        <div class="card-body">
        <h5 class="card-title text-center py-3   text-rainbow-animation">ArmorAI Coin (AAI): Incentivizing Stakeholders in the ArmorAI Ecosystem</h5>
        <p class="card-text pb-4" style={{fontWeight:'400', textAlign:'justify',fontSize:'16px'}}>  We are excited to introduce ArmorAICoin (AAI), a crypto token specifically designed to incentivize and reward stakeholders in the ArmorAI ecosystem. ArmorAI Coin (AAI) will be distributed fairly and transparently, with portions allocated to the community, marketing, core contributors, advisors and partners, development and team, and other essential areas. By implementing ArmorAI Coin, we aim to foster a strong and engaged community that actively supports the safe and ethical use of AI.

        </p>

       </div>
</div>

      </div>    
      
      
     <div className='col-lg-4 col-md-6 col-sm-12 mt-4'>
      
      <div class="card cardsvg2" style={{backgroundColor:'transparent', color:'#fff',height:'auto'}}>
      <div className='mt-4' style={{display:'flex', justifyContent:'center'}}>
        <AiOutlineThunderbolt className='Icon6 p-2' style={{fontSize:'58px', color:'#fff', fontWeight:'600', display:'flex', alignItems:'center',}}/>
      </div>
  
        <div class="card-body">
        <h5 class="card-title text-center py-4  text-rainbow-animation">Commitment to Promoting Safe and Ethical AI Use
        </h5>
        <p class="card-text mb-5 pb-4 pt-2" style={{fontWeight:'400', textAlign:'justify',fontSize:'16px'}}>  At ArmorAI, we are fully committed to promoting the safe and ethical use of AI. We firmly believe that by providing robust tools and services that integrate safety measures into AI development and use, we can help ensure AI is used for the benefit of society as a whole. With our expertise in AI safety solutions, we are dedicated to building a future where AI technologies are developed and utilized responsibly and ethically. 
        </p>

       </div>
</div>

</div>
      </div>    
     </div>
    


      
     </div>
    </div>
    </>
  )
}

export default OurVision;