import React from 'react'
import Header from '../Comp/Header/Header'
import Footer from '../Comp/Footer/Footer'

const PrivacyPolicy = () => {
  return (
    <>

<div>
    <Header/>
</div>

     <div className='container' style={{paddingTop:'100px'}}>
        <div className='row'>
            <div className='col-lg-9 col-sm-12 m-auto'>
                <div className='row'>
                    <div className='col-lg-12 col-sm-12'>
                            
                           <h3 className='text-center text-white my-3 ' style={{fontWeight:'600'}} > <span className='Privacy'>Privacy Policy</span></h3>
                            <p className='text-white '> 
                        This Privacy Policy   explains how Armor Ai   collects, uses, and protects the personal information of users  who interact with our website and services. We are committed to safeguarding your privacy and ensuring that your personal information is protected.<br/> <br/> 

                        <h4 className='my-4' style={{fontWeight:'500', textAlign:'center'}}>Information We Collect</h4>
                        <h5>Personal Information </h5>
                         We may collect personal information such as your name, email address, contact details, and any other information you voluntarily provide to us when you interact with our website, sign up for our services, or contact us for support.<br/> <br/> 
                             
                             <h5 > Usage Information  </h5>
                        
                         We may collect non-personal information about your interactions with our website and services, including your IP address, browser type, device information, and website activity. This information is collected through cookies and similar technologies.<br/> <br/> 

                      <h5>  How We Use Your Information </h5>
                        Provide and Improve Services: We use your personal information to provide and improve our services, respond to your inquiries, and personalize your experience. We may also use aggregated or anonymized data for analytical purposes.<br/> <br/> 

                       <h5>Communications </h5>
                        
                         We may use your personal information to send you important updates, newsletters, marketing communications, and other relevant information about our products and services. You can opt out of receiving marketing communications at any time.<br/> <br/> 

                       <h5> Legal Compliance </h5>
                        
                         We may use and disclose your personal information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests.<br/> <br/> 

                       <h4 className='my-4' style={{textAlign:'center', fontWeight:'500',}}>  Information Sharing and Disclosure</h4>
                        <h5>Third-Party Service Providers</h5>
                         We may share your personal information with trusted third-party service providers who assist us in delivering our services, conducting business operations, and improving our website and services. These third parties are obligated to protect your information and are prohibited from using your personal information for any other purpose.<br/> <br/> 

                         <h5>  Legal Requirements </h5>
                          We may disclose your personal information if required to do so by law or if we believe that such disclosure is necessary to protect our rights, comply with a legal obligation, or protect the safety of our users.<br/> <br/> 

                      <h5> Data Security</h5> 
                        We take reasonable measures to protect the confidentiality and security of your personal information. We use industry-standard technical and organizational safeguards to prevent unauthorized access, use, or disclosure of your information. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.<br/> <br/> 

                        Your Rights
                        You have the right to access, update, and correct your personal information held by us. You may also request the deletion of your personal information, subject to any legal obligations we may have to retain certain data. To exercise these rights or if you have any questions or concerns about our privacy practices, please contact us using the contact details provided below.<br/> <br/> 

                        Changes to this Privacy Policy<br/> <br/> 
                        We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the revised policy on our website. We encourage you to review this Policy periodically for any updates.</p>
                    </div>
                </div>
            </div>
        </div>
     </div>


     <div className='mt-5'>

        <Footer/>
     </div>
    </>
  )
}

export default PrivacyPolicy