import React from 'react'
import Sv from '../../Comp/img/sd1.png' 


const Investor = () => {
  return (
  <>
     

  <section>
   <div className='container  mt-5 '>
    <div className='row'>
     <div className='col-lg-12 col-sm-12'>
     <h1 className='text-center text-white investment' > Why investment in  <span className='Humanity text-rainbow-animation '>Ai Safety</span> is more important? </h1>
     </div>
    </div>



     <div className='row'>
     <div className='col-lg-11   m-auto'>
          
     <div className='row my-5'> 
      <div className='col-lg-6 col-md-6 col-sm-12 2 order-last order-md-second' >
        
      <p className='pt-5 mt-5 text-white  Investing' style={{fontWeight:'400',}} >Investing in<span className='Humanity6  '> AI safety </span> is important to prevent harm from advanced AI systems and ensure they align with <span className='Humanity'> human </span>  values. It's a proactive approach to mitigating risks associated with advancing technology.  </p>

      </div>

      <div className='col-lg-6 col-md-6 col-sm-12 mt-4 order-first order-md-last'>
        <div className='Sv' style={{paddingTop:'80px'}}>
        <img src={Sv} alt='img' className='p-1' style={{width:'100%'}}/> 
      </div>
      </div>
     </div>
   </div>
 </div>
</div>
  </section>
  </>
  )
}

export default Investor