// CarouselComponent.js
import React from 'react'; 
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';
import img3 from '../img/WhatsApp Image 2024-08-29 at 10.06.34_827cd1bc.jpg';
import img2 from '../img/01.jpg';
import img10 from '../img/WhatsApp Image 2024-08-29 at 10.06.35_39f5669f.jpg';
import img4 from '../img/WhatsApp Image 2024-08-29 at 10.06.35_e89aef80.jpg';
import img13 from '../img/002.jpg';
import img12 from '../img/0.jpg';
 
import Carousel from 'better-react-carousel';
import '../../App.css'

const Gallery = () => {
  return (
   <> 
    <section>
    
    <div className='container'>
     <div className='row'>
          
     <div className='col-lg-12 col-sm-12 col-md-12'>
    

    <h1 className='text-center text-white my-5'>Gallery</h1>
     <Carousel cols={2} rows={1} gap={20} loop autoplay={3000} className='mt-4'   scrollSmooth={true}       >
     <Carousel.Item>
     <div  className='mt-5 pt-3' style={{display:'flex', justifyContent:'center'}}>
     <img src={img2} alt='image'  style={{width:'70%',height:'70vh'}} className='mobileImage'/>
     </div>
     </Carousel.Item> 
 
       

 

     <Carousel.Item>
     <div className='mt-5 pt-3' style={{display:'flex', justifyContent:'center'}}>
     <img src={img12} alt='image'   style={{width:'70%', height:'70vh'}} className='mobileImage'/> 
     </div>
     </Carousel.Item>

 

     <Carousel.Item>
     <div className='mt-5 pt-3' style={{display:'flex', justifyContent:'center'}}>
     <img src={img13} alt='image'   style={{width:'70%', height:'70vh'}} className='mobileImage'/> 
     </div>
     </Carousel.Item>

 
     {/* ... */}
   </Carousel> 
     </div>  

 
     </div>
    </div>
    </section>

 
   </>
  );
};

export default Gallery;
