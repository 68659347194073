import React from 'react'
import { NavLink, useLoaderData, useLocation } from 'react-router-dom';
import './Header.css'
import Logo from '../../Comp/img/logo (5).png'
import mod from '../../Comp/img/modal.jpeg'


const Header = () => {

let Location = useLocation();

  return (
     <>
      <section>
      <div className='container'>
       <div className='row'>
        <div className='col-lg-10 m-auto'>
          <div className='row'>
           <div className='col-lg-12 col-sm-12 m-auto  '>  
        <nav className="navbar navbar-expand-lg fixed-top " style={{backgroundColor:'#030614',  }}>
        <div className="container">
          <NavLink className="navbar-brand " to="/"> <img src={Logo} alt='Logo' className='pt-2'/>  </NavLink>
           <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{backgroundColor:'#ccc', border:'1px solid #ccc'}}>
            <span className="navbar-toggler-icon"></span>
          </button>   
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0"> 


            
            <NavLink to='/Roadmap' style={{textDecoration:'none'}}>  
            <li className="nav-item">
                <span className="nav-link  " aria-current="page" style={{borderBottom:Location.pathname=="/Roadmap"?"1px solid #53DBFF ":" ",  color:Location.pathname=="/Roadmap"? "#53DBFF":""  }}  >Roadmap</span>
              </li>
              </NavLink> 
      
      
            <NavLink to='/WhitePaper' style={{textDecoration:'none'}}>  
            <li className="nav-item">
                <span className="nav-link  " aria-current="page" style={{borderBottom:Location.pathname=="/WhitePaper"?"1px solid #53DBFF ":" ",  color:Location.pathname=="/WhitePaper"? "#53DBFF":""  }}  >Whitepaper</span>
              </li>
              </NavLink>
      
      
            <NavLink to='/TokenSystem' style={{textDecoration:'none'}}>  
            <li className="nav-item">
                <span className="nav-link  text-nowrap" aria-current="page" style={{borderBottom:Location.pathname=="/TokenSystem"?"1px solid #53DBFF ":" ",  color:Location.pathname=="/TokenSystem"? "#53DBFF":""  }}  >Token System</span>
              </li>
              </NavLink>
      
      
            <NavLink to='/SportLight ' style={{textDecoration:'none'}}>  
            <li className="nav-item">
                <span className="nav-link text-nowrap " aria-current="page" style={{borderBottom:Location.pathname=="/SportLight"?"1px solid #53DBFF ":" ",  color:Location.pathname=="/SportLight"? "#53DBFF":""}}>  Spot Light on Ai  </span>
              </li>
              </NavLink>

              
            <NavLink to='/Career' style={{textDecoration:'none'}}>  
            <li className="nav-item">

                <span className="nav-link   Aboo" aria-current="page" style={{borderBottom:Location.pathname=="/Career"?"1px solid #53DBFF ":" ",  color:Location.pathname=="/Career"? "#53DBFF":""  }} >Career</span>

              </li>
              </NavLink> 
           
      
      
            <NavLink to='/Sdk' style={{textDecoration:'none'}}>  
            <li class="nav-item dropdown  ">
          <span class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"  style={{borderBottom:Location.pathname=="/Sdk"?"1px solid #53DBFF ":" ",  color:Location.pathname=="/Sdk"? "#53DBFF":""  }}>
          Product
          </span>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown" style={{backgroundColor:'transparent',zIndex:'999', backdropFilter:'blur(10px)', textAlign:'center'}}>

           <NavLink to='/Chat' style={{textDecoration:'none'}}> <li><span class="dropdown-item text-white"  >Safe Ai</span></li></NavLink>

           <NavLink to='/Generate' style={{textDecoration:'none'}}> <li><span class="dropdown-item text-white py-2" >Armor IG</span></li> </NavLink>

           <NavLink to='/SDK' style={{textDecoration:'none'}}> <li><span class="dropdown-item text-white text-nowrap" >SDK & APIs</span></li> </NavLink>

          
             
          </ul>
        </li>
              </NavLink> 
           
            </ul>
              <div >
               <button   data-bs-toggle="modal" data-bs-target="#exampleModalRam"
                className='sst text-nowrap'  style={{backgroundColor:'transparent', color:'#fff', padding:'7px 35px', fontSize:'18px', width:'auto' , }}>Buy   <span className='Dol'>$</span>  <span className='dd'>  AAI </span></button>
              </div> 
          </div>
        </div>
      </nav>
           
           </div>
          </div> 
        </div>
       </div>
      </div>






         {/* By Button modal start */}
          
         <div class="modal fade  " id="exampleModalRam" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{backdropFilter:'blur(7px)', borderRadius:'8px'  }}>
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content" style={{   boxShadow: '0px -1px 43px -4px rgba(224,224,224,1) ' }}>
                {/* <div class="modal-header" style={{border:'none', backgroundColor:'#000'}}> 
                 
                </div> */}
                <div class="modal-body p-0  " style={{backgroundColor:'#000'}}>
                  <div style={{display:'flex', justifyContent:'right',backgroundColor:'#000' }}>
                  <button type="button" class="btn-close p-2 m-2  " data-bs-dismiss="modal" aria-label="Close"
                   style={{backgroundColor:'#fff', borderRadius:'50%'}}></button>
                  </div>
               
                    <div className='mb-3'>
                      <img src={mod} alt='image' style={{width:'100%'}}/>
                    </div>

                </div>
                
              </div>
            </div>
          </div>
             {/* By Button modal end */}
    
</section>
     </>
  )
}

export default Header;