import React from 'react'  
import Lottie from 'react-lottie-player'  
import lottieJson from '../../Comp/img/vidhya.json'
import lottieJson1 from '../../Comp/img/titar.json'
import Ig from '../../Comp/img/bot.png'
import { NavLink } from 'react-router-dom'


const Product = () => {
  return (
    <>
      
    <div className='container'>
     <div className='row'>
      <div className='col-lg-12 col-md-6 col-sm-12 '>
     <h1 className='text-center' style={{paddingTop:'70px', fontSize:'45px'}}> <span className='Subscribe1'>  Our Products </span></h1>
      </div>
     </div>


       <div className='row'>
        <div className='col-lg-11 m-auto'>
         
        <div className='row'>
         <div className='col-lg-6 col-sm-12 mt-5'>
           
         <h1 className='text-center SafeGPT' style={{paddingTop:'70px'}}> <span className='Subscribe1 text-rainbow-animation'> SDKs & API
         Access</span>  </h1>

         <p className='text-white pt-5' style={{fontSize:'18px', textAlign:'justify', lineHeight:'30px',  }}>Developers and businesses can integrate Armor AI via its<span className='Subscribe1' style={{fontWeight:'600'}}> API & SDK </span> access. They can integrate a specific function or build a new application on top of our 
         Safe AI  model. Saving the time, money, and resources required to train an advanced model such as Armor AI from scratch. </p>
         

         
       <NavLink to='https://shield-ai.gitbook.io/armour-ai-whitepaper/our-mission-and-vision/join-the-revolution'>
       <button  className='TRYNOW  text-rainbow-animation'>Try Now</button>
      </NavLink>
         
         </div>
          

         <div className='col-lg-6 col-sm-12 mt-5'>
           
          <div style={{display:'flex', justifyContent:'center'}}>
      <Lottie
      loop
      animationData={lottieJson}
      play
      style={{ width: '100%', height: 'auto' }}
    />
       
      </div> 
         
         
         </div>
        </div>



        <div className='row'> 

         <div className='col-lg-6 col-sm-12 mt-5 order-last order-md-first '>   
          <div style={{display:'flex', justifyContent:'center'}}>
      <Lottie
      loop
      animationData={lottieJson1}
      play
      style={{ width: '100%', height: 'auto' , }}
    /> 
      </div> 
         
         </div>

         <div className='col-lg-6 col-sm-12 mt-5 order-first order-md-last '>
           
         <h1 className='text-center SafeGPT' style={{paddingTop:'100px'}}> <span className='Subscribe1'> 
           SafeAI</span>  </h1>

         <p className='text-white pt-5' style={{fontSize:'18px', textAlign:'justify', lineHeight:'30px',  }}>Reliable & Fast And Secure Source of Information. Ask SafeAI   any question related to <span className='Subscribe1' style={{fontWeight:'600'}}> Blockchain, Crypto </span> and many more. The AI can answer general and technical questions.

         </p> 

         <NavLink to='/Chat'>
         <button  className='TRYNOW  text-rainbow-animation'>Try Now</button>
        </NavLink>
         
         
         </div>
        </div>





        <div className='row'>
         <div className='col-lg-6 col-sm-12 mt-5'>
           
         <h1 className='text-center SafeGPT' style={{paddingTop:'70px'}}> <span className='Subscribe1'> ArmorIG Tools</span>  </h1>

         <p className='text-white pt-3' style={{fontSize:'18px',   lineHeight:'30px',  }}>Unleash your creativity with our powerful ArmorAi's Image Generator Ai Tool.<span className=' text-rainbow-animation' style={{fontWeight:'500'}}> Design custom artwork, enhance social media posts, streamline content creation, and create memorable ads effortlessly.</span> Personalize visuals for e-commerce, experiment with styles, and enjoy the flexibility and adaptability of our user-friendly interface. Elevate your visual content to new heights and witness the magic of our ArmorIG Ai Tool today! </p>
         

         
       <NavLink to='/Generate'>
       <button  className='TRYNOW  text-rainbow-animation '>Try Now</button>
      </NavLink>
         
         </div>
          

         <div className='col-lg-6 col-sm-12  mt-3'>
           
          <div style={{display:'flex', justifyContent:'center'}}>
            <img src={Ig} alt='img' className=' Ig p-5' style={{width:'100%'}}/>
          </div>
         
         
         </div>
        </div>
        </div>
       </div>


 

       
 
    </div>
 


    </>
  )
}

export default Product